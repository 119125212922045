<template>
  <div class="setting setting-page padding-left-layout padding-top-layout padding-right-layout">
    <page-title :title="$t('setting.title_setting')" />
    <div class="setting__main">
      <div class="setting__main__grid-btns" v-for="(mainItem, index) in mainItems" :key="index">
        <h2 class="setting__main__grid-btns--head2">{{ mainItem.header}}</h2>
        <div class="setting__main__grid-btns--items">
          <router-link class="setting__main__grid-btns--items__item setting-btn"
            v-for="(item, index) in mainItem.itemBtns" :key="index" :to="item.path">
            <!-- <div class="setting__main__grid-btns--items__item--image">
              <img :src="getSettingIcon(item.image)" alt="" />
            </div>
            <div class="setting__main__grid-btns--items__item--label">{{ item.text }}</div>
            <div class="setting__main__grid-btns--items__item--arrow-svg"><img src="@/assets/images/setting/arrow.svg"
                alt="" /></div> -->

            <div class="setting-btn-text">
              <div class="setting-btn-img">
                <img :src="getSettingIcon(item.image)" alt="" />
              </div>
              <div class="setting-text">{{ item.text }}</div>
            </div>
            <div class="arrow-svg"><img src="@/assets/images/setting/arrow.svg" alt="" /></div>

          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import {ROLE} from '@/constants/role';
import { SETTING } from '@/constants/settingScreen'
import PageTitle from '@/components/pageTitle/pageTitle.vue';
export default {
  name: 'Setting',
  components: {
    PageTitle,
  },
  data() {
    return {
      breadcrum: [
        {
          text: this.$t('setting.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('setting.label_setting'),
          disabled: true,
          href: ROUTES.SETTING,
        },
      ],
      isHidden: false,
      enterpriseGroup: this.$t("group_enterprise_management.button_enterprise_management_setting"),
      mainItems: [
        {
          key: SETTING.DISCHARGE,
          header: this.$t('setting_screen_new_ui.setting_screen_header_discharge_setting'),
          itemBtns: [
            {
              key: 6,
              text: this.$t('setting.button_db_customize'),
              path: ROUTES.CREATE_DATA_CUSTOMIZE,
              image: 'db-customize.svg',
            },
            {
              key: 7,
              text: this.$t('setting.button_setting_auto_approval'),
              path: ROUTES.SETTING_AUTO_APPROVAL,
              image: 'auto-approval.svg',
            },
          ]
        },
        {
          key: SETTING.ACCOUNT,
          header: this.$t('setting_screen_new_ui.setting_screen_header_account_setting'),
          itemBtns: [
            {
              key: 8,
              text: this.$t('setting.button_setting_language'),
              path: ROUTES.SETTING_LANGUAGE,
              image: 'language.svg',
            },
            { key: 9, text: this.$t('setting.button_change_pass'), path: ROUTES.CHANGE_PASSWORD_USER, image: 'change-pass.svg' },
            {
              key: 11,
              text: this.$t('setting.button_setting_csv_format'),
              path: ROUTES.CSV_FORMAT,
              image: 'csv-format.svg',
            },
            {
              key: 12,
              text: this.$t('setting_format_number.button_setting_format_number_on_setting'),
              path: ROUTES.SETTING_DISPLAY_FORMAT_NUMBER,
              image: 'setting-format-number.svg',
            }
          ]
        },
        {
          key: SETTING.SERVICE,
          header: this.$t('setting_screen_new_ui.setting_screen_header_external_collaboration'),
          itemBtns: [
            {
              key: 10,
              text: this.$t('linked_service_management.button_linked_service_management_setting'),
              path: ROUTES.LINKED_SERVICE_MANAGEMENT,
              image: 'linked_service_management.svg',
            }
          ]
        }
      ]
    };
  },
  mounted() {
    this.updateBreadCrumb(this.breadcrum);
    this.updateItems();
  },
  computed: {
    ...mapGetters(['getRoles']),
    ...mapState('userData', ['currentUser', 'planType', "existDbSource"]),
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    getSettingIcon(image) {
      return require(`@/assets/images/setting/${image}`);
    },
    redirectPage(url) {
      return this.$router.push({ path: url });
    },
    updateItems() {
      const currentRole = this.currentUser.user.role_id;
      const itemsByOnlyAdmin = currentRole === ROLE.ADMIN;
      if (itemsByOnlyAdmin) {
        let itemBusiness = {
          key: SETTING.BUSINESS,
          header: this.$t('setting_screen_new_ui.setting_screen_header_business_management'),
          itemBtns: [
            { key: 1, text: this.$t('setting.button_user_management'), path: ROUTES.USER_MANAGEMENT, image: 'user.svg' },
            { key: 2, text: this.$t('setting.button_facility_management'), path: ROUTES.FACILITY_MANAGEMENT, image: 'facility.svg' },
            { key: 3, text: this.$t('setting.button_management_viewer'), path: ROUTES.VIEWER_MANAGEMENT, image: 'viewer.svg' },
            { key: 4, text: this.$t('setting.button_fiscal_year'), path: ROUTES.CALCULATION_TERM, image: 'calculation.svg' },
          ]
        }

        // Add user management, facility, viewer for admin role
        if (!this.mainItems.some(item => item.key === SETTING.BUSINESS)) {
          this.mainItems.unshift(itemBusiness);
        }

        const newDBSettingToInsert = { text: this.$t('idea_db_source.title_btn_setting_db_source'), path: this.existDbSource ? ROUTES.LIST_IDEA_DB_SOURCE : ROUTES.REGISTER_DB_SOURCE, image: 'idea-db-source.svg' };
        // const index = 6;
        // this.items.splice(index, 0, newDBSettingToInsert);
        this.mainItems = this.mainItems.map((menu) => {
          if (menu.key === SETTING.DISCHARGE) {
            return {
              ...menu,
              itemBtns: [
                ...menu.itemBtns.slice(0, 1),
                newDBSettingToInsert,
                ...menu.itemBtns.slice(1)
              ]
            }
          }

          return menu;
        });

      } else {
        // Service only for role admin
        this.mainItems.splice(-1)
        // Auto approve only for admin + approver
        if (currentRole === ROLE.USER) {
          this.mainItems = this.mainItems.map((menu) => {
            return {
              ...menu,
              itemBtns: menu.itemBtns.filter(item => item.key !== SETTING.SETTING_AUTO_APPROVAL)
            };
          });
        }
      }
    },
  },
  watch: {
    planType(newValue) {
      if (this.planType?.is_pcaf && this.currentUser.user.role_id === ROLE.ADMIN) {
        // Add Enterprise for admin if plan is PCAF
        const itemEntepriseGroupInsert = { text: this.enterpriseGroup, path: ROUTES.GROUP_ENTERPRISE_MANAGEMENT, image: 'group-enterprise.svg' };
        this.mainItems.forEach((menu) => {
          if (menu.key === SETTING.BUSINESS) {
            // Chỉ tìm kiếm và thêm khi chưa tồn tại item
            if (!menu.itemBtns.some((btn) => btn.text === itemEntepriseGroupInsert.text)) {
              menu.itemBtns.push(itemEntepriseGroupInsert);
            }
          }
        });
      }

      if (this.planType?.is_reduction_target && this.currentUser.user.role_id === ROLE.ADMIN) {
        const itemIcp = { key: 5, text: this.$t('setting.button_reduction_target'), path: ROUTES.REDUCTION_TARGET_LIST_MANAGEMENT, image: 'reduction.svg' };
        for (let i = 0; i < this.mainItems.length; i++) {
          const menu = this.mainItems[i];

          if (menu.key === SETTING.DISCHARGE) {
            // Kiểm tra nếu itemIcp chưa tồn tại trong itemBtns
            const exists = menu.itemBtns.find((btn) => btn.text === itemIcp.text);

            if (!exists) {
              menu.itemBtns.push(itemIcp);
            }
            break;
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.setting {
  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap: 24px;
    &__grid-btns {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      &--head2 {
        color: $goldMid;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 1.6px;
      }
      &--items {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        flex-direction: column;
        &__item {
          display: flex;
          padding: 8px 8px 8px 12px;
          align-items: center;
          gap: 12px;
          align-self: stretch;
          border-radius: 4px;
          background: $monoWhite;
          text-decoration: none;
          box-shadow: 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11), 0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17), 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24);
          &--image {
              display: flex;
              width: 48px;
              // height: 48px;
              padding: 6.5px 5px 6.14px 5px;
              justify-content: center;
              align-items: center;
          }
          
          &--label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            color: $monoBlack;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.42px;
          }
          
          &--arrow-svg {
            display: flex;
            width: 36px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
          }
          &.setting-btn {
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $monoOffWhite;
            cursor: pointer;
            box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11);
            border-radius: 4px;
            //margin-bottom: 16px;
            padding: 8px 8px 8px 12px;
            transition: 0.2s ease-out;

            .setting-btn-text {
              display: flex;
              align-items: center;
              gap: 12px;

              div {
                // padding-left: 12px;
                color: $monoBlack;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.03em;
                //text-align: center;
              }
            }

            &:hover {
              background-color: $monoWhite;
              box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17);
            }

            &:active {
              border: 2px solid $blueMid;
            }

            &.disabled {
              background: $monoLight;
              pointer-events: none;

              &:hover {
                cursor: unset;
              }
            }
          }

          .arrow-svg {
            width: 36px;
            height: 48px;
          }
        }
      }
    }
  }
}

@include desktop {
  .setting {
      &__main {
        margin: 0 auto;
        &__grid-btns {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          margin: 0 auto;
  
          &--head2 {
            color: $goldMid;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 1.6px;
          }
  
          &--items {
            gap: 24px;

            display: grid;
            grid-template-columns: repeat(4, 1fr);
            justify-content: center;
  
            &__item {
              display: flex;
              width: 200px;
              padding: 26px 16px 22px 16px;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;
              gap: 16px;
              border-radius: 4px;
              background: var(--Mono-OffWhite, #F7F7F2);
              box-shadow: 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11), 0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17), 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24);
              // &--image {
                //   display: flex;
                //   width: 48px;
                //   padding: 6.5px 5px 6.14px 5px;
                //   justify-content: center;
                //   align-items: center;
                // }
                // &--arrow-svg {
                //   display: none;
                // }
              &.setting-btn {
                width: 200px;
                height: 136px;
                margin: 0px;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                padding: unset;

                &:hover {
                  cursor: pointer;
                }

                .arrow-svg {
                  display: none;
                }

                .setting-btn-text {
                  display: flex;
                  flex-flow: column;
                  gap: 0px;
                  padding: 26px 10px 22px;

                  img {
                    flex-shrink: 0;
                  }

                  div {
                    text-align: center;
                  }

                  .setting-btn-img {
                    display: flex;
                    width: 48px;
                    height: 48px;
                    padding: 6.5px 8.5px 7.54px 7.5px;
                    justify-content: center;
                    align-items: center;
                  }

                  .setting-text {
                    margin-top: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
}
@media screen and (min-width: 1024px) and (max-width: 1176px) {
  .setting {
    &__main {
      &__grid-btns {
        &--items {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}
</style>

